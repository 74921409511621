import {
    FormControl,
    Grid,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import CountrySelect from "../../components/SelectFields/CountrySelect";
import CompanySelect from "../../components/SelectFields/CompanySelect";
import React, {useCallback, useEffect, useState} from "react";
import {useAuth} from "../../hooks/authContext";
import ClientAPI from "../../api/fuelcheckAPI";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {DhlHookUpRequestBody, LCenter, VehicleWithTrailerDto} from "../../types";

import {useNavigate} from "react-router-dom";
import Select from "@mui/material/Select";
import Trailers from "./Trailers";
import {PaperHeader, ContentBoxWrapper, PageWrapper, PaperTableWrapper} from "../../AppStyles";
import Loader from "../../components/Loading/Loader";
import {useTranslation} from "../../hooks/useTranslation";

export default function DHLUploaderPage() {
    const {isAdmin} = useAuth();
    const [country, setCountry] = useState('');
    const [username, setUsername] = useState("");
    const [hookUpList, setHookUpList] = useState<VehicleWithTrailerDto[]>([]);
    const [showAddHookUpForm, setShowAddHookUpForm] = useState<boolean>(false);
    const [typeOfModal, setTypeOfModal] = useState<"New" | "Edit" | null>(null);
    const [pickedHookUp, setPickedHookUp] = useState<VehicleWithTrailerDto | null>(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [lCenterList, setLCenterList] = useState<LCenter[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMobileButtons = Boolean(anchorEl);
    const navigate = useNavigate()
    const translate = useTranslation()
    const handleOpenMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMobileButtons = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        if (username) {
            setIsFetching(true);
            const getHookUps = ClientAPI.dhl.getHookUps(username);
            const getLCenters = ClientAPI.dhl.getLCenters();
            Promise.all([getHookUps, getLCenters])
                .then(([hookUps, lCenters]: [VehicleWithTrailerDto[], LCenter[]]) => {
                    setHookUpList(hookUps);
                    setLCenterList(lCenters);
                }).finally(() => setIsFetching(false))

        } else {
            setHookUpList([])
        }
    }, [username]);

    const handleChangeCountry = useCallback((country: string) => {
        setCountry(country);
    }, []);

    const handleChangeUsername = useCallback((name: string) => {
        setUsername(name);
    }, []);


    function sortHookUpList() {
        if (hookUpList) {
            return hookUpList.sort((a, b) => {
                if (a.lcenter < b.lcenter) return 1
                if (a.lcenter > b.lcenter) return -1
                return 0;
            });
        }else {
            return []
        }
    }

    const sortedHookUpList = sortHookUpList();

    function handleCheckboxChange(hookUp: VehicleWithTrailerDto) {

    }

    function handleClickAdd() {
        setTypeOfModal("New")
        setShowAddHookUpForm(true)
        setPickedHookUp(null)
        handleCloseMobileButtons()
    }

    function handleClickEdit(hookUp: VehicleWithTrailerDto) {

    }

    function openDeleteDialog(hookUp: VehicleWithTrailerDto) {

    }

    function handleAddHookUp(hookUp: DhlHookUpRequestBody) {
        // ClientAPI.denmark.addHookUp(vehicle)
        //     .then(resp => setHookUpList([...hookUpList, resp]));
        // TODO: handle promise error return.

    }

    function handleUpdateHookUp(hookUp: VehicleWithTrailerDto) {
        console.log("hookUp", hookUp)

        // ClientAPI.denmark.updateHookUp(vehicle).then(resp => {
        //     const updatedVehicles = hookUpList.filter(veh => veh.vehicleId !== resp.vehicleId);
        //     setHookUpList([...updatedVehicles, resp])
        // })
        // TODO: handle promise error return.
    }

    function handleClickInfo(hookUp: VehicleWithTrailerDto) {
        navigate(`/denmark/${hookUp.truckRegno}`)
    }
    // will be needed new implementation when change DB structure
    return (
        <PageWrapper>
            <ContentBoxWrapper basis={"60%"}>
                <PaperHeader>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <CountrySelect onCountryChange={handleChangeCountry}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CompanySelect onUsernameChange={handleChangeUsername} country={country}/>
                        </Grid>
                    </Grid>
                </PaperHeader>
                {/*TABLE*/}
                <PaperTableWrapper>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6" component="h6">
                                {translate('setting.denmark.currentTrailerMapping')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button size={"small"}
                                    variant="contained"
                                    color={"secondary"}
                                    disabled={!username}
                                    onClick={handleClickAdd}
                            >
                                {translate('buttons.add')}
                            </Button>
                        </Grid>
                    </Grid>
                    {isFetching ?
                        <Loader/>
                        :
                        <TableContainer sx={{marginTop: "1rem", overflowY: "auto"}}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{translate('report.dataField.vehicle')}</TableCell>
                                        <TableCell align="left">{translate('setting.denmark.table.trailer')}</TableCell>
                                        <TableCell align="left">{translate('setting.dhl.table.lcenter')}</TableCell>
                                        <TableCell align="center">{translate('setting.user.table.enabled')}</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {sortedHookUpList.map((hookUp) => (
                                        <TableRow
                                            key={hookUp.truckRegno}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell align="left" component="th" scope="row">
                                                {hookUp.truckRegno}
                                            </TableCell>
                                            <TableCell align="left" component="th" scope="row">
                                                {hookUp.trailerRegno}
                                            </TableCell>
                                            <TableCell align="left" component="th" scope="row">
                                                <FormControl fullWidth size="small">
                                                    <Select
                                                        value={hookUp.lcenter}
                                                        id={"LCenter"}
                                                        name={"LCenter"}
                                                        onChange={() => handleUpdateHookUp(hookUp)}
                                                    >
                                                        {lCenterList.map(center =>
                                                            <MenuItem value={center.id}
                                                                      key={center.id}>{center.lcenter}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Switch checked={hookUp.enabled}
                                                        onChange={() => handleCheckboxChange(hookUp)}
                                                        color={"secondary"} size={"small"}/>
                                            </TableCell>
                                            <TableCell align="right" component="th" scope="row">
                                                {/*DESKTOP BUTTONS*/}
                                                <Box sx={{display: {xs: "none", lg: "block"}}}>
                                                    <Button size={"small"} variant="outlined" color={"secondary"}
                                                            sx={{marginRight: "1rem"}}
                                                            onClick={() => handleClickInfo(hookUp)}
                                                    >
                                                        {translate('setting.denmark.table.info')}
                                                    </Button>
                                                    <Button size={"small"} variant="outlined" color={"secondary"}
                                                            sx={{marginRight: "1rem"}}
                                                            onClick={() => handleClickEdit(hookUp)}
                                                    >
                                                        {translate('buttons.edit')}
                                                    </Button>
                                                    <Button size={"small"}
                                                            variant="outlined"
                                                            color={"secondary"}
                                                            onClick={() => openDeleteDialog(hookUp)}>
                                                        {translate('buttons.delete')}
                                                    </Button>
                                                </Box>
                                                {/*MOBILE BUTTONS VIEW*/}
                                                {/*TODO: normal shadow of menu!*/}
                                                <Box sx={{display: {xs: "block", lg: "none"}}}>
                                                    <IconButton
                                                        id="control-button"
                                                        aria-controls={openMobileButtons ? 'control-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={openMobileButtons ? 'true' : undefined}
                                                        onClick={handleOpenMobileMenu}>
                                                        <MoreVertIcon/>
                                                    </IconButton>
                                                    <Menu
                                                        id="buttons-menu"
                                                        aria-labelledby="menu-button"
                                                        anchorEl={anchorEl}
                                                        open={openMobileButtons}
                                                        onClose={handleCloseMobileButtons}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <MenuItem
                                                            onClick={() => handleClickInfo(hookUp)}>Info</MenuItem>
                                                        <MenuItem
                                                            onClick={() => handleClickEdit(hookUp)}>Edit</MenuItem>
                                                        <MenuItem
                                                            onClick={() => openDeleteDialog(hookUp)}>Delete</MenuItem>
                                                    </Menu>
                                                </Box>

                                            </TableCell>

                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </PaperTableWrapper>
            </ContentBoxWrapper>

            <Trailers username={username}/>

            {/*MODAL WINDOW EDIT/ADD HOOK-UP*/}
            {/*{showAddHookUpForm &&*/}
            {/*    <AddEditDHLDialog setShowAddHookUpForm={setShowAddHookUpForm} showAddHookUpForm={showAddHookUpForm}*/}
            {/*                      addHookUp={handleAddHookUp} updateHookUp={handleUpdateHookUp} lCenters={lCenterList}*/}
            {/*                      typeOfModal={typeOfModal} pickedHookUp={pickedHookUp} hookUpList={hookUpList}*/}
            {/*                      username={username}/>}*/}
            {/*{(showConfirmationDialog && pickedHookUp) &&*/}
            {/*    <ConfirmDeleteDialog show={showConfirmationDialog} hookUp={pickedHookUp} closeDialog={closeDeleteDialog}*/}
            {/*                         deleteHookUp={handleDeleteTruck}/>}*/}
        </PageWrapper>
    );
}