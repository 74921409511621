import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import ClientAPI from "../../api/fuelcheckAPI";
import Loader from "../../components/Loading/Loader";
import {FormControl, Grid, Table, TableContainer} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Vehicle} from "../../types";
import {ContentBoxWrapper, PageWrapper, PaperHeader, PaperTableWrapper} from "../../AppStyles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useAuth} from "../../hooks/authContext";
import Typography from "@mui/material/Typography";
import {useTranslation} from "../../hooks/useTranslation";

function TranVPage() {
    const queryClient = useQueryClient();
    const [searchVehicleDisplayName, setSearchVehicleDisplayName] = useState('')
    const [searchVehicleVin, setSearchVehicleVin] = useState('')
    const [searchedVehicle, setSearchedVehicle] = useState<Vehicle | null>(null)
    const [searchError, setSearchError] = useState(false)
    const translate = useTranslation()

    const {data: tranvVehicles, isFetching} = useQuery({
        queryFn: () => ClientAPI.tranv.getAllVehiclesTranV(),
        queryKey: ['tranv']
    })

    const {mutate: addVehicle} = useMutation({
        mutationFn: ClientAPI.tranv.addVehicle,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['tranv']})
        }
    })

    const {mutate: deleteVehicle} = useMutation({
        mutationFn: ClientAPI.tranv.deleteVehicle,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['tranv']})
        }
    })

    function handleChangeSearchRegno(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setSearchError(false)
        setSearchVehicleDisplayName(event.target.value)
    }

    function handleChangeSearchVin(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setSearchError(false)
        setSearchVehicleVin(event.target.value)
    }

    function handleAddVehicle() {
        const isAlreadyEnabled = tranvVehicles?.find(v => v.vehicleId === searchedVehicle?.vehicleId);
        if (searchedVehicle && !isAlreadyEnabled) {
            addVehicle(searchedVehicle);
            setSearchedVehicle(null)
        } else {
            //TODO: notify that vehicle already added
        }
    }

    function handleOnClickSearch() {
        if (searchVehicleVin) {
            ClientAPI.vehicles.getVehicleByVin(searchVehicleVin)
                .then(vehicle => {
                    setSearchedVehicle(vehicle)
                    setSearchVehicleDisplayName('')
                })
                .catch(() => {
                    setSearchError(true)
                    setSearchedVehicle(null)
                });
        } else if (searchVehicleDisplayName) {
            ClientAPI.vehicles.getVehicleByDisplayName(searchVehicleDisplayName)
                .then(vehicle => {
                    setSearchedVehicle(vehicle)
                    setSearchVehicleVin('')
                })
                .catch(() => {
                    setSearchError(true)
                    setSearchedVehicle(null)
                });
        }

    }

    function handleDelete(id: number) {
        deleteVehicle(id)
    }

    function dataView(data: string | number | null) {
        return data ? data : "NO DATA"
    }

    return (

        <PageWrapper>
            <ContentBoxWrapper>
                <PaperHeader>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6">
                                TranV
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="vehicleId"
                                    label= {translate('report.excel.columnHeader.registrationNumber')}
                                    required
                                    error={searchError}
                                    variant="outlined"
                                    value={searchVehicleDisplayName}
                                    disabled={searchVehicleVin !== ''}
                                    onChange={handleChangeSearchRegno}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="vehicleId"
                                    label="VIN"
                                    required
                                    error={searchError}
                                    variant="outlined"
                                    value={searchVehicleVin}
                                    disabled={searchVehicleDisplayName !== ''}
                                    onChange={handleChangeSearchVin}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button variant="contained"
                                    type={"button"}
                                    fullWidth
                                    onClick={handleOnClickSearch}
                                    sx={{bgcolor: "secondary.main", width: "100%"}}>
                                {translate('tranv.page.search')}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {searchedVehicle &&
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translate('setting.gefco.table.vehicleId')}</TableCell>
                                            <TableCell>{translate('report.excel.columnHeader.registrationNumber')}</TableCell>
                                            <TableCell>VIN</TableCell>
                                            <TableCell>{translate('setting.user.table.userName')}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{dataView(searchedVehicle.vehicleId)}</TableCell>
                                            <TableCell>{dataView(searchedVehicle.displayName)}</TableCell>
                                            <TableCell>{dataView(searchedVehicle.vin)}</TableCell>
                                            <TableCell>{dataView(searchedVehicle.username)}</TableCell>
                                            <TableCell>
                                                <Button variant="contained"
                                                        type={"button"}
                                                        onClick={handleAddVehicle}
                                                        sx={{bgcolor: "secondary.main", width: "100%"}}>
                                                    {translate('buttons.add')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            }

                            {searchError &&
                                <Typography variant="h6" component="h6">
                                    {translate('tranv.page.noVehicle')}
                                </Typography>
                            }
                        </Grid>

                    </Grid>
                </PaperHeader>

                {/*TABLE*/}
                <PaperTableWrapper>
                    <TableContainer sx={{maxHeight: "75vh"}}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate('setting.gefco.table.vehicleId')}</TableCell>
                                    <TableCell>{translate('report.excel.columnHeader.registrationNumber')}</TableCell>
                                    <TableCell>VIN</TableCell>
                                    <TableCell>{translate('setting.user.table.userName')}</TableCell>
                                    <TableCell>{translate('')}{translate('tranv.page.disable')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isFetching ?
                                    <TableRow>
                                        <TableCell>
                                            <Loader/>
                                        </TableCell>
                                    </TableRow>
                                    :
                                    tranvVehicles?.map(vehicle =>
                                        <TableRow key={vehicle.id}>
                                            {/*TODO: redirect to tranv.fuelcheck.eu/position/${vehicleId}*/}
                                            <TableCell>
                                                {vehicle.vehicleId?
                                                    <a href={`http://tranv.fuelcheck.eu/position/${vehicle.vehicleId}`}
                                                       rel="noreferrer"
                                                       target={"_blank"}>{vehicle.vehicleId}</a>
                                                    :
                                                    "NO DATA"
                                                }
                                            </TableCell>
                                            <TableCell>{dataView(vehicle.registrationNumber)}</TableCell>
                                            <TableCell>{dataView(vehicle.vin)}</TableCell>
                                            <TableCell>{dataView(vehicle.username)}</TableCell>
                                            <TableCell>
                                                <Button variant="contained"
                                                        type={"button"}
                                                        onClick={() => handleDelete(vehicle.id)}
                                                        sx={{bgcolor: "secondary.main", width: "100%"}}>
                                                    {translate('buttons.delete')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </PaperTableWrapper>
            </ContentBoxWrapper>
        </PageWrapper>
    )
}

export default TranVPage;