import React, {useState} from "react"
import {LoginFormContainer, StyledForm} from './Login.styled'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Person from '@mui/icons-material/Person'
import {useAuth} from "../../hooks/authContext";
import {useTranslation} from "../../hooks/useTranslation";

interface Props {
    pickedUser: string | undefined
}

export default function LoginForm({pickedUser}: Props) {
    const {logIn} = useAuth()
    const [name, setName] = useState("");
    const [pass, setPass] = useState('')
    const translate = useTranslation()

    function nameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handlePassChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPass(event.target.value);
    }

     function handleSubmit(event: React.FormEvent<HTMLFormElement>,) {
        event.preventDefault();
        if (pickedUser) {
            logIn(pickedUser, "pass");
        } else {
            logIn(name,pass)
        }
    }

    return (
        <LoginFormContainer>
            <StyledForm onSubmit={handleSubmit}>
                {pickedUser ?
                    <>
                        <Avatar sx={{bgcolor: 'secondary.main', width: 56, height: 56}}>
                            <Person/>
                        </Avatar>
                        <h3>{translate('login.form.welcomeBack')}, {pickedUser}!</h3>
                    </>
                    :
                    <h3>{translate('login.form.welcome')}!</h3>
                }
                {!pickedUser &&
                    <TextField id="email" label= {translate('login.form.address')} variant="standard" required fullWidth
                               onChange={nameChange} value={name} type="text"/>
                }
                <TextField id="password" label={translate('start.password')} variant="standard" fullWidth
                           onChange={handlePassChange} value={pass} type="password"/>
                <FormControlLabel control={<Checkbox value="remember" color="primary"/>} label= {translate('login.form.rememberMe')}
                                  sx={{alignSelf: 'flex-start'}}/>
                <Button variant="contained" type="submit" fullWidth sx={{bgcolor: 'secondary.main'}}
                        data-testid="login-btn"
                >{translate('start.logon')}</Button>
                <Button variant="text" sx={{color: 'secondary.main'}}>{translate('login.form.forgotPassword')}</Button>
            </StyledForm>
        </LoginFormContainer>
    )
}