import React from 'react'
import {Header} from './Login.styled'
import Button from '@mui/material/Button';
import HelpOutline from '@mui/icons-material/HelpOutline'
import {useTranslation} from "../../hooks/useTranslation";

interface Props {
    setPickedUser: React.Dispatch<React.SetStateAction<string|undefined>>
}

export default function LoginHeader ({ setPickedUser}:Props) {
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
    // const open = Boolean(anchorEl);

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     if(anchorEl!==null){
    //         return
    //     }else{
    //         setAnchorEl(event.currentTarget);
    //     }
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    // function handlePickUser (user:string){
    //     setPickedUser(user)
    //     handleClose()
    // }
    const translate = useTranslation()
    return(
        <Header>
            {/*<div>*/}
            {/*    <Button*/}
            {/*        id="basic-button"*/}
            {/*        aria-controls={open ? 'basic-menu' : undefined}*/}
            {/*        aria-haspopup="true"*/}
            {/*        aria-expanded={open ? 'true' : undefined}*/}
            {/*        onClick={handleClick}*/}
            {/*        endIcon={<ArrowDropDown/>}*/}
            {/*        startIcon={<Person/>}*/}
            {/*        sx={{color:'primary.light'}}*/}
            {/*    >*/}
            {/*        Login preset*/}
            {/*    </Button>*/}
            {/*    <Menu*/}
            {/*        id="basic-menu"*/}
            {/*        anchorEl={anchorEl}*/}
            {/*        open={open}*/}
            {/*        onClose={handleClose}*/}
            {/*        MenuListProps={{*/}
            {/*            'aria-labelledby': 'basic-button',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {persistedUsers && persistedUsers.map((user, index)=> <MenuItem key={index} onClick={()=>handlePickUser(user)}>{user}</MenuItem>)}*/}
            {/*    </Menu>*/}
            {/*</div>*/}

                <Button
                    id="basic-button"
                    startIcon={<HelpOutline/>}
                    sx={{color:'primary.light'}}
                >
                    {translate('login.form.help')}
                </Button>

        </Header>
    )
}