import React from 'react'
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "../../hooks/useTranslation";

function DontExistPage() {
    const navigate = useNavigate()
    const translate = useTranslation()
    return (
        <div>
            <Button onClick={()=>navigate("/")}>BACK to dashboard</Button>
            <h1>{translate('button.error')}</h1>
        </div>
    )
}

export default DontExistPage
