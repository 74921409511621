import React from 'react'
import {useTranslation} from "../../hooks/useTranslation";

interface IPageProps {
    pageName: string
}
function NoRightsPage({pageName}:IPageProps) {
    const translate = useTranslation()
    return (
        <h1>{translate('page.message.noRights')}: {pageName}</h1>
    )
}

export default NoRightsPage
