export type LoginData = {
    username: string,
    password: string
}

export type AuthResponseData = {
    username: string,
    nickname: string,
    country: string,
    defaultProfile: number,
    privileges: number,
    isAdmin: boolean,
    access_token: string,
    refresh_token: string
}

export type  VehicleInfo = {
    vin: string,
    displayName: string,
    odometer: number,
    vehicleId: number,
    profileId: number
    dynafleet_unit_number: string,
    description: string
}
export type VehiclePositionRow = {
    positionTime: Date;
    latitude: number;
    longitude: number;
    driverId?: number;
    country?: string;
}
export type DriverInfo = {
    driverId: number,
    firstName: string,
    lastName: string,
    driverIdentification: string
}

export type BasicCustomerInfoState = {
    name: string,
    drivers: number,
    vehicles: number,
    lastDriverStatusApi: boolean,
    lastPositionApi: boolean,
    lastVehicleStatusApi: boolean
}

export type CustomerInfo = {
    username: string,
    apiUsername?: string,
    apiPassword?: string,
    apiOffset: number,
    trackingData: boolean,
    vehicleReportData: boolean,
    driverReportData: boolean,
    positioningPlus: boolean,
    expirationDate: string | null,
    companyName: string,
    apiUsernameV2: string,
    apiPasswordV2: string
}

export type UserInfo = {
    username: string,
    password: string
    isAdmin: boolean,
    nickname: string
    apiName: string | null,
    apiPassword: string | null,
    apiOffset: number | null,
    enabled: boolean,
    companyAddress: string,
    companyName: string,
    country: string,
    defaultProfile: number | null,
    privileges: number,
    expirationDate: string | null,
    driverPass: string,
    dietPass: string,
    positionPlus: boolean,
}

export type UserResponse = Omit<UserInfo, "password">
//     {
//     username: string
//     isAdmin: boolean
//     nickname: string
//     apiName: string | null
//     apiPassword: string | null
//     apiOffset: string | null
//     enabled: boolean
//     companyAddress: string | null
//     companyName: string | null
//     country: string
//     defaultProfile: number
//     privileges: number
//     expirationDate: Date | null
//     driverPass: string | null
//     dietPass: string | null
//     positionPlus: boolean
// }

export enum ApplicationZones {
    // DRIVING_TECHNIQUE_REPORT
    DT = 1,

    // DIETS_REPORT
    DA = 2,

    // VEHICLE_REPORT
    VP = 3,

    // DENMARK_UPLOAD
    DK = 8,

    // GEFCO_UPLOAD
    // GF = '16',

    // DHL_UPLOAD
    DH = 32,

    // TWO_DIET_RATES = '64',
    // SETTINGS = '128',

    // LOGBOOK_REPORT
    LB = 256,

    // DHL_PL_UPLOAD
    // DP = '512',
    ADMIN = 10000
}

export enum ReportConversionTypes {
    PRUMERNA_SPOTREBA = "PRUMERNA_SPOTREBA",
    NEUSPORNA_JIZDA_P = "NEUSPORNA_JIZDA_P",
    VOLNY_DOJEZD = "VOLNY_DOJEZD",
    NEUSPORNA_JIZDA_L = "NEUSPORNA_JIZDA_L",
    VOLNOBEH = "VOLNOBEH",
    POCET_BRZDENI = "POCET_BRZDENI",
    PREKROCENI_RYCHLOSTI = "PREKROCENI_RYCHLOSTI",
    TEMPOMAT = "TEMPOMAT"
}

export type ReportConversion = {
    id?: number,
    type: ReportConversionTypes,
    from: number,
    to: number | "Infinity",
    points: number,
    profileId: number
}

export type DietValidityInterval = {
    id?: number,
    home: string,
    username: string,
    validityInterval: string,
}
export type DietRate = {
    id?: number,
    country: string,
    rate: number | string,
    currency: string
}
export type IDietRateRowData = {
    rate: number,
    country: Country,
    currency: string
}

export type Country = {
    shortcut: string,
    name: string
}
export type Currency = {
    currencyShortcut: string
}

export type DietRateRequestDto = {
    id?: number
    home: string,
    username: string,
    country: string,
    rate: number | null,
    rate2: number | null,
    currency: string,
    validityInterval: string,
}

export type VehicleProfileDto = {
    profileId: number,
    nameKey: string,
    profileOwner: string,
    profileShortcut: string,
    tempomatActivationPercentage: number
}

export type Depot = {
    depotName: string
    latitude: number
    longitude: number
    distance: number
    delay: number
    beforeInterval: number
    afterInterval: number
    id?: number,
    username: string
}

export type IdleSettings = {
    username: string,
    delay: number,
    distance: number,
    enabled: boolean
}

export type TruckInfo = {
    truckId: number,
    vehicleId: number,
    type: number,
    vvvono: string,
    username: string,
    enabled: boolean
    displayName: string,
}
export type TruckRequestBody = {
    vehicleId: number,
    type: number,
    vvvono: string,
    username: string,
    enabled: boolean
    displayName: string,
}

export type UploadAuditInfo = {
    positionTime: string,
    latitude: number,
    longitude: number,
    httpResponse: string
}

export type VehicleWithTrailerDto = {
    truckRegno: string,
    trailerRegno: string,
    enabled: boolean,
    lcenter: number
}
export type DhlHookUp = {
    truckId: number,
    vehicleId: number,
    trailerId: number,
    time: string, //timestamp
    username: string,
    enabled: boolean,
    lcenterId: number
}
export type DhlHookUpRequestBody = Omit<DhlHookUp, "truckId">
//     {
//     vehicleId: number,
//     trailerId: number,
//     time: string, //timestamp
//     username: string,
//     enabled: boolean,
//     lcenterId: number
// }

export type TrailerDhl = {
    trailerId: number,
    type: number,
    regno: string,
    username: string
}

export type LCenter = {
    id: number,
    lcenter: string
}

export type TranVVehicle = {
    id: number,
    vehicleId: number,
    vin: string,
    registrationNumber: string,
    username: string
}

export type Vehicle = {
    vehicleId: number,
    vin: string,
    displayName: string,
    username: string
}
const locales = [
    "ar",
    "be",
    "bg",
    "ca",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "es",
    "et",
    "fi",
    "fr",
    "ga",
    "hi",
    "hr",
    "hu",
    "in",
    "is",
    "it",
    "iw",
    "ja",
    "ko",
    "lt",
    "lv",
    "mk",
    "ms",
    "mt",
    "nl",
    "no",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "sl",
    "sq",
    "sr",
    "sv",
    "th",
    "tr",
    "uk",
    "vi",
    "zh"
]
type Locale = typeof locales[number];

export type Language = {
    id: number,
    languageName: string,
    languageFullName: string,
    active: boolean,
    languageNationalName: string,
    flag: string,
}

export type BaseText = {
    id: number,
    baseText: string,
    defaultTranslation: string
}

export type TranslationDto = {
    translation: string,
    defaultTranslation: string,
    baseTextId: number;
}

export type TranslationUpdateDto = {
    "id": {
        "languageId": number,
        "baseTextId": number
    },
    "translation": string
}

export type AdminReportData = {
    totalVehiclesCount: number;
    countries: AdminReportCountry[]
}

export type AdminReportCountry = {
    name: string;
    vehiclesCount: number;
    companies: AdminReportCompany[]
}

export type AdminReportCompany = {
    username: string;
    vehiclesCount: number;
    vehicles: AdminReportVehicle[];
}

export type AdminReportVehicle = {
    id: number;
    vin: string;
    registrationNumber: string;
    firstLoadTime: string;
}

export type AdminReportRequestBody = {
    "fromDate": string,
    "toDate": string,
    "loadVehicles": boolean,
    "outputType": "XLS" | "PDF",
    "locale": string
}

export type DriverActivityReportRequestBody = {
    driverId: number,
    fromTime: string,
    toTime: string,
    outputType: "XLS" | "PDF",
    locale: string,
}

export type DriverActivityReportData = {
    company: string;
    driverName: string;
    activitiesPerDay: ActivitiesPerDay[];
    activitiesPerCountry: ActivitiesPerCountry;
    ratesPerCountry: RatesPerCountry;
}

export type ActivitiesPerDay = {
    date: string;
    activitiesPerCountry: ActivitiesPerCountry;
    ratesPerCountry: RatesPerCountry;
    totalActivitiesDuration: Activity;
}
export type ActivitiesPerCountry = {
    [key in Locale]: Activity
}

export type Activity = {
    driving: number;
    working: number;
    resting: number;
    waiting: number;
};

export type RatesPerCountry = {
    [key in Locale]: Rate
}
export type Rate = {
    tariff: number,
    amount: number,
    currency: string
}